
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import Modal from '@/components/common/Modal.vue';
  import { Dividend } from '@/models/assets/Dividends';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import { DeleteDividendParam, UpdateDividendParam } from '@/store/modules/dividend';

  @Component({
    components: {
      Modal,
      FormDatePicker,
      FormInput,
      ValidationObserver,
    },
  })
  export default class ModifyDividendModal extends Vue {
    @Prop() dividend!: Dividend;
    @Prop() assetId!: string;
    @Prop() mode!: 'delete' | 'modify';

    @Action(addToastMessage) addToastMessage!: Function;
    @Action updateDividend!: ({ newTotalAmount, newTaxes, newCosts, assetId, dividendId, period }: UpdateDividendParam) => Promise<void>;
    @Action deleteDividend!: ({ assetId, dividendId }: DeleteDividendParam) => Promise<void>;
    @StateClass('dividend') stateDividend!: State['dividend'];
    newAmount = this.dividend.amount;
    newCosts = this.dividend.costs || 0;
    newTaxes = this.dividend.taxRate || 0;
    newPeriod = this.dividend.period.toDate();
    FormIcons = FormIcons;
    didFormChange = false;

    @Watch('stateDividend.status')
    onstateAssetChange(dividendStatus: DataContainerStatus): void {
      if (dividendStatus !== DataContainerStatus.Processing) {
        this.$emit('close');
      }
    }

    get isProcessing(): boolean {
      return this.stateDividend?.status === DataContainerStatus.Processing;
    }

    onUpdateDividend(): void {
      this.updateDividend({
        period: this.newPeriod,
        dividendId: this.dividend.id as string,
        assetId: this.assetId,
        newTotalAmount: Number(this.newAmount),
        newTaxes: Number(this.newTaxes),
        newCosts: Number(this.newCosts),
      });
    }

    onDeleteDividend(): void {
      this.deleteDividend({
        dividendId: this.dividend.id as string,
        assetId: this.assetId,
      });
    }
  }
