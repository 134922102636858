
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import Modal from '@/components/common/Modal.vue';
  import { Valuation } from '@/models/assets/Valuation';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import { DeleteValuationParam, UpdateValuationParam } from '@/store/modules/valuation';
  import { firebase } from '@/boot/firebase';

  @Component({
    components: {
      Modal,
      FormDatePicker,
      FormInput,
      ValidationObserver,
    },
  })

  export default class ModifyValuationModal extends Vue {
    @Prop() valuation!: Valuation;
    @Prop() assetId!: string;
    @Prop() mode!: 'delete' | 'modify';
    @Action(addToastMessage) addToastMessage!: Function;
    @Action updateValuation!: ({ assetId, valuationId, updatedValuation }: UpdateValuationParam) => Promise<void>;
    @Action deleteValuation!: ({ assetId, valuationId }: DeleteValuationParam) => Promise<void>;
    @StateClass('valuation') stateValuation!: State['valuation'];
    newAmount = 0;
    newDescription = '';
    newDate: Date | null = null;
    FormIcons = FormIcons;
    didFormChange = false;

    mounted(): void {
      this.newAmount = this.valuation.amount || 0;
      this.newDescription = this.valuation.description || '';
      this.newDate = this.valuation.date.toDate();
    }

    @Watch('stateValuation.status')
    onStateValuationStatusChange(valuationStatus: DataContainerStatus): void {
      if (valuationStatus !== DataContainerStatus.Processing) {
        this.$emit('close');
      }
    }

    get isProcessing(): boolean {
      return this.stateValuation?.status === DataContainerStatus.Processing;
    }

    onUpdateValuation(): void {
      const dateNow = firebase.firestore.Timestamp.now();
      this.updateValuation({
        assetId: this.assetId,
        valuationId: this.valuation.id,
        updatedValuation: {
          updatedDateTime: dateNow,
          date: this.newDate,
          description: this.newDescription,
          amount: Number(this.newAmount),
        },
      });
    }

    onDeleteValuation(): void {
      this.deleteValuation({
        valuationId: this.valuation.id,
        assetId: this.assetId,
      });
    }
  }
