
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import BigNumber from 'bignumber.js';
  import { bloqifyFirestore } from '@/boot/firebase';
  import { State } from '@/models/State';
  import { Asset } from '@/models/assets/Asset';
  import { Dividend } from '@/models/assets/Dividends';
  import { DataContainerStatus } from '@/models/Common';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import ModifyDividendModal from '@/components/assets/ModifyDividendModal.vue';
  import { AddDividendParam } from '@/store/modules/dividend';
  import Valuations from '@/components/assets/assetFinancials/Valuations.vue';

  @Component({
    components: {
      ModifyDividendModal,
      Valuations,
      FormDatePicker,
      FormInput,
      ValidationObserver,
    },
  })
  export default class AssetFinancials extends Vue {
    @Action getAssetById!: ({ id }: { id: string }) => void;
    @Action addDividend!: ({ assetId, amount, period, costs, taxRate }: AddDividendParam) => void;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action bindFirestoreReferences!: Function;
    @Action unbindFirestoreReferences!: Function;
    @StateClass('boundAsset') asset!: Asset;
    @StateClass('boundDividends') boundDividends!: Dividend[];
    @StateClass('asset') stateAsset!: State['asset'];
    @StateClass('dividend') stateDividend!: State['dividend'];
    // Dividend fields
    amount: number | null = null;
    costs: number | null = null;
    taxRate: number | null = null;
    period: Date | null = null;
    netto: Date | null = null;
    loadingPromise: Promise<any> = Promise.resolve();
    showModifyDividendModal: boolean = false;
    selectedDividend: Dividend | null = null;
    FormIcons = FormIcons;
    modeModifyDividendModal: 'delete' | 'modify' = 'modify';
    dividendOptions = {
      headings: {
        period: 'Period',
        amount: 'Rent amount',
        taxRate: 'Taxes',
        netAmount: 'Netto',
        modify: '',
      },
      sortable: ['period', 'amount', 'costs', 'taxRate', 'netAmount'],
      filterable: ['period', 'amount'],
      columnsClasses: {
        period: 'list__filter--name align-middle',
        amount: 'list__filter--sold align-middle',
        modify: 'list__filter--modify align-middle',
      },
      skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
    };

    @Watch('stateDividend.status')
    onNewDividendError(newStatus: DataContainerStatus): void {
      if (newStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `Dividend successfully ${this.stateDividend!.operation === 'addDividend' ? 'added' : 'modified'}`,
          type: 'success',
        });
      } else if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.stateDividend!.error || 'There was a problem with adding/updating dividends',
          type: 'danger',
        });
      }
    }

    @Watch('assetId', { immediate: true })
    async onNewAsset(newAssetId: string | null): Promise<void> {
      if (newAssetId) {
        this.loadingPromise = this.bindFirestoreReferences([
          {
            name: 'boundAsset',
            ref: bloqifyFirestore.collection('assets').doc(newAssetId),
          },
          {
            name: 'boundDividends',
            ref: bloqifyFirestore.collection(`assets/${newAssetId}/dividends`)
              .where('deleted', '==', false)
              .orderBy('period', 'desc'),
          },
        ]);
      }
    }

    beforeDestroy(): void {
      this.unbindFirestoreReferences([{ name: 'boundDividends' }]);
    }

    get assetId(): string {
      return this.$route.params.assetId;
    }

    get defaultDate(): Date {
      const today = new Date();
      today.setMonth(today.getMonth() - 1);
      return today;
    }

    get dividends(): Dividend[] {
      return this.boundDividends.map((dividend): Dividend => ({ ...dividend, id: dividend.id }));
    }

    get getCalculatedNetAmount(): number {
      if (this.amount) {
        const amountMinusCosts = new BigNumber(this.amount).minus(this.costs || 0);
        const taxRateAmount = amountMinusCosts.times(this.taxRate || 0).dividedBy(100);
        return amountMinusCosts.minus(taxRateAmount).dp(2).toNumber();
      }

      return 0;
    }

    openDividendModal(dividend: Dividend, mode: 'delete' | 'modify'): void {
      this.showModifyDividendModal = true;
      this.modeModifyDividendModal = mode;
      this.selectedDividend = dividend;
    }

    async submitDividends(): Promise<void> {
      this.addDividend({
        assetId: this.assetId,
        amount: Number(this.amount),
        costs: Number(this.costs),
        taxRate: Number(this.taxRate),
        period: this.period as Date,
      });
    }
  }
