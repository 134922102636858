
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import { bloqifyFirestore } from '@/boot/firebase';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { Valuation } from '@/models/assets/Valuation';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import ModifyValuationModal from '@/components/assets/ModifyValuationModal.vue';

  @Component({
    components: {
      ModifyValuationModal,
      FormInput,
      FormDatePicker,
      ValidationObserver,
    },
  })

  export default class Valuations extends Vue {
    @Action(addToastMessage) addToastMessage!: Function;
    @Action bindFirestoreReferences!: Function;
    @Action unbindFirestoreReferences!: Function;
    @Action addValuation!: Function;
    @Action updateValuation!: Function;
    @Action deleteValuation!: Function;

    @StateClass('boundValuations') boundValuations!: Valuation;
    @StateClass('valuation') stateValuation!: State['valuation'];

    @Prop() newAssetId!: string;

    formattedNewValuations = [];
    showModifyValuationModal: boolean = false;
    selectedValuation: Valuation | null = null;
    modeModifyValuationModal: 'delete' | 'modify' = 'modify';
    FormIcons = FormIcons;
    loadingPromise: Promise<any> = Promise.resolve();
    // Revaluation fields
    valuationAmount: number | null = null;
    valuationDescription: string | null = null;
    valuationDate: Date | null = null;
    valuationOptions = {
      headings: {
        valuationDate: 'Valuation Date',
        amount: 'Amount',
        description: 'Description',
      },
      filterable: ['period', 'amount'],
      columnsClasses: {
        period: 'list__filter--period align-middle',
        amount: 'list__filter--amount align-middle',
        modify: 'list__filter--modify align-middle',
      },
      skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
    };

    @Watch('stateValuation.status')
    onNewStateValuationStatus(newStatus: DataContainerStatus): void {
      if (this.stateValuation?.operation === 'addValuation'
        || this.stateValuation?.operation === 'updateValuation'
        || this.stateValuation?.operation === 'deleteValuation'
      ) {
        if (newStatus === DataContainerStatus.Success) {
          this.addToastMessage({
            text: `Valuation successfully ${this.stateValuation?.operation === 'addValuation'
              ? 'created' : this.stateValuation?.operation === 'updateValuation'
              ? 'updated' : 'deleted'}`,
            type: 'success',
          });
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.stateValuation!.error || `There was a problem while
            ${this.stateValuation?.operation === 'addValuation'
              ? 'creating' : this.stateValuation?.operation === 'updateValuation'
              ? 'updating' : 'deleting'} the valuation`,
            type: 'danger',
          });
        }
      }
    }

    get defaultDate(): Date {
      const today = new Date();
      today.setMonth(today.getMonth() - 1);
      return today;
    }

    @Watch('boundValuations')
    onNewValuations(newValuations): void {
      // get id value that is hidden by default
      this.formattedNewValuations = newValuations.map((newValuation): object => ({ ...newValuation, id: newValuation.id }));
    }

    async mounted(): Promise<void> {
      if (this.newAssetId) {
        const assetRef = await bloqifyFirestore.collection('assets').doc(this.newAssetId);
        this.loadingPromise = this.bindFirestoreReferences([
          {
            name: 'boundValuations',
            ref: assetRef.collection('valuations')
              .where('deleted', '==', false)
              .orderBy('date', 'desc'),
          },
        ]);
      }
    }

    submitValuation(): void {
      this.addValuation({
        assetId: this.newAssetId,
        amount: Number(this.valuationAmount),
        description: this.valuationDescription,
        date: this.valuationDate,
      });
      // reset values
      this.valuationAmount = 0;
      this.valuationDescription = '-';
      this.valuationDate = this.defaultDate;
    }

    openValuationModal(valuation: Valuation, valuationId, mode: 'delete' | 'modify'): void {
      this.showModifyValuationModal = true;
      this.modeModifyValuationModal = mode;
      this.selectedValuation = valuation;
    }

    beforeDestroy(): void {
      this.unbindFirestoreReferences([
        { name: 'boundValuations' },
      ]);
    }
  }
